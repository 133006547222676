import humanizeDuration from 'humanize-duration';
import i18next, { t } from 'i18next';

import { adjustDateByTimezone } from './topicDatesHelpers';

export const getShortDate = (passedDate: string, userTimezone?: string) => {
  const tzAdjustedDate = adjustDateByTimezone(passedDate, userTimezone);
  return tzAdjustedDate.toLocaleString(i18next.language, { month: 'short', day: 'numeric' });
};

// ex. Jan 12, 2021
export const getShortDateWithYear = (passedDate: string, userTimezone?: string) => {
  const tzAdjustedDate = adjustDateByTimezone(passedDate, userTimezone);

  return tzAdjustedDate.toLocaleString(i18next.language, { month: 'short', day: 'numeric', year: 'numeric' });
};

export const GetHydratedShortDate = (isClientHydrated: boolean, passedDate: string, userTimezone?: string) => {
  return isClientHydrated ? getShortDate(passedDate, userTimezone) : '';
};

export const GetHydratedShortDateWithYear = (isClientHydrated: boolean, passedDate: string, userTimezone?: string) => {
  return isClientHydrated ? getShortDateWithYear(passedDate, userTimezone) : '';
};

export const getEngagementTimeFormat = (timeInSeconds: number) => {
  const timeInHours = timeInSeconds / 3600;
  const timeRounded = (Math.round(timeInHours * 100) / 100).toFixed(1);
  if (timeRounded === '0.0') return '0';
  return timeRounded;
};

export const getHumanizedTime = (time: number) => {
  return humanizeDuration(Number(time + '000'), { conjunction: ' and ', round: true, largest: 2 });
};

export const getReadableTimeSinceCurrentDate = (date: string, userTimezone?: string) => {
  const nowFromThenDateDiff = (Date.now() - Date.parse(date)) / 1000; // time difference in seconds

  if (nowFromThenDateDiff < 60) return t('dates.justNow');
  if (nowFromThenDateDiff < 60 * 60) {
    const minutes = Math.floor(nowFromThenDateDiff / 60);
    return t('dates.numMinutes', { count: minutes });
  }
  if (nowFromThenDateDiff < 60 * 60 * 24) {
    const hours = Math.floor(nowFromThenDateDiff / (60 * 60));
    return t('dates.numHours', { count: hours });
  }
  if (nowFromThenDateDiff < 60 * 60 * 24 * 7) {
    const days = Math.floor(nowFromThenDateDiff / (60 * 60 * 24));
    return t('dates.numDays', { count: days });
  }

  // If the date wasn't less than 7 days old, just show the short date
  return getShortDate(date, userTimezone);
};

export const getShortReadableTimeSinceCurrentDate = (date: string, sendJustNow = true) => {
  const nowFromThenDateDiff = (Date.now() - Date.parse(date)) / 1000; // time difference in seconds

  if (nowFromThenDateDiff < 60 && sendJustNow) return t('dates.justNow');
  if (nowFromThenDateDiff < 60 * 60) {
    const minutes = Math.floor(nowFromThenDateDiff / 60);
    return t('dates.numM', { minutes });
  }
  if (nowFromThenDateDiff < 60 * 60 * 24) {
    const hours = Math.floor(nowFromThenDateDiff / (60 * 60));
    return t('dates.numH', { hours });
  }
  if (nowFromThenDateDiff < 60 * 60 * 24 * 30) {
    const days = Math.floor(nowFromThenDateDiff / (60 * 60 * 24));
    return t('dates.numD', { days });
  }
  if (nowFromThenDateDiff < 60 * 60 * 24 * 365) {
    const months = Math.floor(nowFromThenDateDiff / (60 * 60 * 24 * 30));
    return t('dates.numMo', { months });
  }

  const years = Math.floor(nowFromThenDateDiff / (60 * 60 * 24 * 365));
  return t('dates.numY', { years });
};

export const getYear = (date?: string) => {
  const newDate = date ? new Date(date) : new Date();
  return newDate.toLocaleString(i18next.language, { year: 'numeric' });
};

export const getUnlocalizedYear = (date?: string) => {
  const newDate = date ? new Date(date) : new Date();
  return newDate.getFullYear();
};
